<template>
  <v-dialog
    width="700"
    v-model="showWorkoutInfo"
    :fullscreen="isPhone"
    overlay-color="charcoal"
    overlay-opacity=".95"
    @keydown.esc="$emit('cancel')"
  >
    <v-card
      v-if="loaded"
      tile
      class="noscroll-x charcoal silver--text px-0 d-flex flex-column"
      elevation="1"
    >
      <BaseCloseDialogHeader
        :heading="workout.name"
        @cancel="$emit('cancel')"
      />
      <template>
        <v-card-actions :class="textSizeXSmall + ' silver--text py-0 px-2'">
          Used {{ workout.counter }} times, copied {{ workout.copied }} times.
          <v-spacer />
          <span
            v-if="workout.public == 'Yes'"
            :class="textSizeXSmall + ' progressActive--text'"
          >
            PUBLIC
          </span>
          <span v-else :class="textSizeXSmall + ' orange--text'">
            PRIVATE
          </span>
        </v-card-actions>
        <v-card-actions :class="textSizeXSmall + ' silver--text pt-0 px-2'">
          Last Used:
          {{ workout.is_unused == 'No' ? prettyDate(workout.last_run) : 'NA' }}
          <v-spacer />
          <span title="Workout Type" :class="textSizeXSmall + ' silver--text'">
            {{ workout.type }}
            <span class="text-uppercase" v-if="workout.type == 'Hybrid'"
              >- {{ workout.xmode }}</span
            >
          </span>
        </v-card-actions>
      </template>
      <v-card-actions class="pb-2 pt-0">
        <v-divider class="charcoal lighten-1" />
        <v-badge color="success" inline :content="badgeContent(workout)">
        </v-badge>
        <v-divider class="charcoal lighten-1" />
      </v-card-actions>
      <v-card-text :class="textSizeXSmall + ' silver--text pt-0 pl-2'">
        {{ workout.description }}
      </v-card-text>
      <v-card-text class="pl-2 charcoal">
        <v-list v-if="showExercises" dense subheader class="charcoal">
          <v-list-item
            v-for="exercise in workout.exercises"
            :key="workout.id + exercise.id + exercise.sort"
            class="pl-1"
          >
            <v-row dense>
              <v-col cols="12">
                <v-badge color="success" left inline :content="exercise.sort">
                  <div
                    :class="textSizeXSmall + ' ml-1 paper--text charcoal link'"
                    :title="'View Details for ' + exercise.name"
                    @click="showExercise(exercise.id)"
                  >
                    {{ exercise.name }}
                    <span class="caption silver--text">
                      x
                      {{ exerciseInfo(exercise) }}
                    </span>
                  </div>
                </v-badge>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
      </v-card-text>
      <template v-if="workout.public == 'Yes'">
        <v-spacer />
        <v-card-actions class="px-0">
          <v-spacer />
          <BaseActionButton
            :large="!isPhone"
            icon="mdi-pencil-box-multiple-outline"
            :title="'Copy Workout: ' + workout.name"
            label="Copy"
            text
            color="success"
            @clickedThis="copyWorkout(workout)"
          />
        </v-card-actions>
      </template>
    </v-card>
    <v-dialog
      :width="dialogWidth"
      v-model="showExerciseInfo"
      :fullscreen="isPhone"
      overlay-color="charcoal"
      overlay-opacity=".97"
    >
      <ExerciseInfo
        :exerciseid="selectedExercise"
        @keydown.esc="cancelInfo"
        @cancel="cancelInfo"
        @swipe_off="cancelInfo"
      />
    </v-dialog>
    <v-dialog
      v-model="showCopyDialog"
      persistent
      width="700"
      overlay-color="charcoal"
      overlay-opacity=".95"
      :fullscreen="isPhone"
    >
      <v-card tile class="d-flex flex-column">
        <BaseCloseDialogHeader
          heading="Copy Workout?"
          @cancel="showCopyDialog = false"
        />
        <v-card-text class="px-2 mt-2">
          This allows you to copy this public workout. You will then be able to
          tweak it and use it as you see fit.
          <v-card-text class="title silver--text px-0">
            Current Workout Name
            <div
              class="body-1 charcoal--text"
              v-html="selectedWorkout.name"
            ></div>
          </v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="selectedWorkout.newname"
                ref="name"
                :counter="50"
                label="New Workout Name"
                prepend-icon="mdi-rename-box"
                @focus="$event.target.select()"
              />
            </v-col>
          </v-row>
          <v-card-text class="body-1 pt-5 px-0">
            Update the workout name above and then click the
            <span class="accent--text">Copy </span> button.
          </v-card-text>
        </v-card-text>
        <v-spacer />
        <v-card-actions class="px-0">
          <BaseActionButton
            icon="mdi-close"
            label="Cancel"
            color="charcoal"
            plain
            @clickedThis="showCopyDialog = false"
          />
          <v-spacer />
          <BaseActionButton
            :disabled="isEmpty(selectedWorkout.newname)"
            label="Copy"
            plain
            title="Copy Workout"
            color="success"
            icon="mdi-pencil-box-multiple-outline"
            @clickedThis="copyNow()"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>
<script>
import axios from 'axios'
import { appConfig, builder } from '@/store/helpers.js'
import util from '@/mixins/util.js'
const ExerciseInfo = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/ExerciseInfo.vue')

export default {
  components: { ExerciseInfo },
  mixins: [util],
  name: 'ListWorkouts',
  data: () => ({
    name: '',
    oldname: '',
    description: '',
    filterLabel: 'All',
    workout: {},
    repSchemes: [],
    selectedWorkout: {},
    selectedExercise: '',
    showExerciseInfo: false,
    showCopyDialog: false,
    showDetails: false,
    showExercises: true,
    loaded: false,
    refresh: false,
    filtering: false,
    showUpgradeWarning: false,
    searchTerm: '',
    sortDesc: false
  }),
  beforeMount() {
    this.loadWorkout()
  },
  props: {
    workoutid: {
      type: String
    },
    showWorkoutInfo: {
      type: Boolean
    }
  },
  watch: {
    showExerciseInfo: function(value) {
      this.$store.dispatch('session/toggleModal', value)
    }
  },
  mounted() {
    window.addEventListener('message', this.receiveMessage)
    this.loaded = true
  },
  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage)
  },

  computed: {
    ...appConfig,
    ...builder
  },
  methods: {
    receiveMessage(event) {
      if (event.data.type == 'CLOSE_MODAL' && event.data.key == 'UoMc92xqvA') {
        if (this.showExerciseInfo) this.cancelInfo()
      }
    },
    showExercise(exerciseid) {
      this.selectedExercise = exerciseid
      this.showExerciseInfo = true
    },
    cancelInfo() {
      this.selectedExercise = ''
      this.showExerciseInfo = false
    },
    copyWorkout(workout) {
      this.selectedWorkout = workout
      this.selectedWorkout.newname = 'Copy Of ' + this.selectedWorkout.name
      this.showCopyDialog = true
    },
    copyNow() {
      return axios
        .post(this.baseURL + '/workout/copy', {
          workoutid: this.selectedWorkout.workoutid,
          description: this.selectedWorkout.description,
          newname: this.selectedWorkout.newname,
          now: this.now()
        })
        .then(response => {
          if (response.status == 200) {
            this.showCopyDialog = false
            this.toast(
              'Workout ' + this.selectedWorkout.newname + ' was copied!'
            )
            this.selectedWorkout = null
            this.$emit('cancel')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },

    loadRepschemes() {
      return axios
        .get(this.baseURL + '/tools/repschemes/all', {})
        .then(response => {
          if (response.status == 200) {
            this.repSchemes = response.data.data
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },

    loadWorkout() {
      this.loaded = false
      return axios
        .get(this.baseURL + '/workout/info/' + this.workoutid, {})
        .then(response => {
          if (response.status == 200) {
            this.workout = response.data.data
            this.workout.exercises = JSON.parse(this.workout.exercises)
            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
